import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import { DialogJobEventBus } from "@/core/lib/job/dialog.job.lib";
import { AttachmentEventBus } from "@/core/lib/attachment.lib";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";
import PatchJobMixin from "@/core/lib/job/patch.job.mixin";
import moment from "moment-timezone";
import ObjectPath from "object-path";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin, JobRequestMixin, PatchJobMixin],
  data() {
    return {
      job: 0,
      quotationId: 0,
      invoiceId: 0,
      // jobTab: "tab-visit",
      closerDialog: false,
      contentLoaded: false,
      particuleFieldLoading: false,
      serviceFormDialog: false,
      timeTrackingDialog: false,
      invoiceReminderDialog: false,
      recurrenceLoading: false,
      pageLoading: true,
      deleteValid: false,
      deleteLoading: false,
      deleteDialog: false,
      daily_recurrence: {},
      monthly_recurrence: {},
      weekly_recurrence: {},
      deleteValidateText: null,
      detail: {
        description: "",
        additional_remarks: "",
        priority: 0,
      },
      attachmentTotal: 0,
      customer: {},
      property: {},
      billing: {},
      contact_person: {},
      line_items: [],
      visits: [],
      teams: [],
      invoice_reminders: [],
      invoiceScheduleList: [
        { id: 1, value: "Monthly on the last date of the month" },
        { id: 2, value: "After each visit is completed" },
        { id: 3, value: "As needed - no reminders" },
        { id: 4, value: "Once when job is closed" },
      ],
      moreActions: [],
      updateDetailDialog: {
        title: false,
        description: false,
        reference: false,
      },
      updateDetail: {
        title: null,
        description: null,
        reference: null,
      },
    };
  },
  methods: {
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "duplicate":
          _this.duplicateJob();
          break;
        case "convert_to_invoice":
          _this.convertToInvoice();
          break;
        case "mark_as_hold":
          _this.patchTicketParams({ status: 5 }).then(() => {
            _this.loadContent();
          });
          break;
        case "mark_as_cancel":
          _this.patchTicketParams({ status: 6 }).then(() => {
            _this.loadContent();
          });
          break;
        case "mark_as_closed":
          _this.patchTicketParams({ status: 3 }).then(() => {
            _this.loadContent();
          });
          break;
        case "mark_as_open":
          _this.patchTicketParams({ status: 2 }).then(() => {
            _this.loadContent();
          });
          break;
        case "mark_as_draft":
          _this.patchTicketParams({ status: 1 }).then(() => {
            _this.loadContent();
          });
          break;
        case "download_checklist":
          window.open(_this.detail.checklist_excel_url, "_blank");
          break;
        case "download_service_report":
          window.open(_this.detail.service_form_excel_url, "_blank");
          break;
        case "download_pdf":
          window.open(
            process.env.VUE_APP_API_URL +
              "ticket/" +
              _this.detail.id +
              "/pdf/download",
            "_blank"
          );
          break;
        case "print":
          window.open(
            process.env.VUE_APP_API_URL +
              "ticket/" +
              _this.detail.id +
              "/print",
            "_blank"
          );
          break;
      }
    },
    duplicateJob() {
      const _this = this;
      if (_this.getPermission("job:create")) {
        _this.$router.push(
          _this.getDefaultRoute("job.create", {
            query: {
              duplicate: ObjectPath.get(_this, "detail.id", 0),
              customer: ObjectPath.get(_this, "customer.customer", 0),
              contact_person: ObjectPath.get(
                _this,
                "contact_person.contact_person",
                0
              ),
              property: ObjectPath.get(_this, "property.property", 0),
              project: ObjectPath.get(_this, "detail.project.id", 0),
              contract: ObjectPath.get(_this, "detail.contract.id", 0),
            },
          })
        );
      }
    },
    convertToInvoice() {
      const _this = this;
      if (_this.getPermission("invoice:create")) {
        _this.$router.push(
          _this.getDefaultRoute("invoice.create", {
            query: {
              job: _this.detail.id,
              customer: _this.lodash.toSafeInteger(_this.customer.customer),
              contact_person: _this.lodash.toSafeInteger(
                _this.contact_person.contact_person
              ),
              property: _this.lodash.toSafeInteger(_this.property.property),
            },
          })
        );
      }
    },
    patchTicketParams(param) {
      const _this = this;
      if (_this.getPermission("job:update")) {
        return new Promise((resolve, reject) => {
          _this
            .patchJob(_this.detail.id, param)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    closeAllDialog() {
      this.serviceFormDialog = false;
      this.timeTrackingDialog = false;
    },
    updateField(type) {
      const _this = this;
      if (_this.getPermission("job:update")) {
        _this.onEscParticularField();
        _this.updateDetailDialog[type] = true;
        if (type == "payment_due") {
          _this.updateDetail[type] = _this.detail[type].id;
        } else {
          _this.updateDetail[type] = _this.detail[type];
        }
        setTimeout(function () {
          if (_this.$refs[type]) {
            _this.$refs[type].focus();
          }
        }, 50);
      }
    },
    onEscParticularField() {
      this.updateDetailDialog = {
        title: false,
        description: false,
        reference: false,
      };
      this.updateDetail = {
        title: null,
        description: null,
        reference: null,
      };
    },
    updateParticularField() {
      const _this = this;
      _this.particuleFieldLoading = true;
      _this
        .patchTicketParams(_this.updateDetail)
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.loadContent();
          _this.particuleFieldLoading = false;
          _this.onEscParticularField();
        });
    },
    loadContent() {
      const _this = this;
      _this.pageLoading = true;
      _this
        .getJob(_this.job)
        .then((response) => {
          _this.job = response.ticket.id;
          _this.detail = response.ticket;
          _this.moreActions = _this.detail.more_actions;
          if (_this.lodash.isEmpty(_this.detail.invoice) === false) {
            _this.invoiceId = _this.lodash.toSafeInteger(
              _this.detail.invoice.id
            );
          }
          if (_this.lodash.isEmpty(_this.detail.quotation) === false) {
            _this.quotationId = _this.lodash.toSafeInteger(
              _this.detail.quotation.id
            );
          }
          _this.customer = response.customer;
          _this.property = response.property || new Object();
          _this.billing = response.billing || new Object();
          _this.contact_person = response.contact_person || new Object();
        })
        .catch((error) => {
          _this.logError(error);
          _this.$router.go(-1);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.onEscParticularField();
        });
    },
    loadRecurrence() {
      const _this = this;
      _this.recurrenceLoading = true;
      _this
        .getRecurrence(_this.job)
        .then((response) => {
          _this.daily_recurrence = response.daily_recurrence;
          _this.monthly_recurrence = response.monthly_recurrence;
          _this.weekly_recurrence = response.weekly_recurrence;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.recurrenceLoading = false;
        });
    },
    loadAttachments() {
      AttachmentEventBus.$emit("load:attachment", this.job);
    },
    createInvoiceReminder() {
      this.invoiceReminderDialog = true;
    },
    createdInvoiceReminder() {
      this.invoiceReminderDialog = false;
    },
    updateAttachmentTotal(param) {
      this.attachmentTotal = param;
    },
    createCloser() {
      this.closerDialog = true;
    },
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Job",
        route: "job",
      },
      {
        title: "Detail",
      },
    ]);
    _this.loadContent();
    _this.loadRecurrence();
    _this.loadAttachments();
  },
  created() {
    const _this = this;
    _this.job = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.job || _this.job <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.jobTab = _this.$route.query.tab;
    }

    DialogJobEventBus.$on("load:job", function () {
      _this.loadContent();
    });
  },
  beforeDestroy() {
    /*DialogJobEventBus.$off("load:job");*/
  },
  computed: {
    canUpdateJob() {
      if (this.detail && this.detail.status != 1) {
        return false;
      }
      return this.getPermission("job:update");
    },
    jobTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.jobTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "other";
      },
    },
    PropertyDetail() {
      let propertyArr = new Array();
      if (this.property) {
        if (this.property.unit_no) {
          propertyArr.push(this.property.unit_no);
        }
        if (this.property.street_1) {
          propertyArr.push(this.property.street_1);
        }
        if (this.property.street_2) {
          propertyArr.push(this.property.street_2);
        }
        if (this.property.zip_code) {
          propertyArr.push(this.property.zip_code);
        }
        if (this.CityName) {
          propertyArr.push(this.CityName);
        }
        if (this.StateName) {
          propertyArr.push(this.StateName);
        }
        if (this.CountryName) {
          propertyArr.push(this.CountryName);
        }
      }
      return propertyArr.join(", ");
    },
    BillingDetail() {
      let billingArr = new Array();
      if (this.billing) {
        if (this.billing.unit_no) {
          billingArr.push(this.billing.unit_no);
        }
        if (this.billing.street_1) {
          billingArr.push(this.billing.street_1);
        }
        if (this.billing.street_2) {
          billingArr.push(this.billing.street_2);
        }
        if (this.billing.zip_code) {
          billingArr.push(this.billing.zip_code);
        }
        if (this.CityBillingName) {
          billingArr.push(this.CityBillingName);
        }
        if (this.StateBillingName) {
          billingArr.push(this.StateBillingName);
        }
        if (this.CountryBillingName) {
          billingArr.push(this.CountryBillingName);
        }
      }
      return billingArr.join(", ");
    },
    CityBillingName() {
      return this.lodash.isEmpty(this.billing.city) === false
        ? this.billing.city.name
        : null;
    },
    StateBillingName() {
      return this.lodash.isEmpty(this.billing.state) === false
        ? this.billing.state.name
        : null;
    },
    CountryBillingName() {
      return this.lodash.isEmpty(this.billing.country) === false
        ? this.billing.country.name
        : null;
    },
    CityName() {
      return this.lodash.isEmpty(this.property.city) === false
        ? this.property.city.name
        : null;
    },
    StateName() {
      return this.lodash.isEmpty(this.property.state) === false
        ? this.property.state.name
        : null;
    },
    CountryName() {
      return this.lodash.isEmpty(this.property.country) === false
        ? this.property.country.name
        : null;
    },
    StartedOn() {
      if (this.detail.started_at) {
        return moment().isAfter(this.detail.started_at)
          ? "Started On"
          : "Starts On";
      }
      return "Starts On";
    },
    getVisitScheduleType() {
      if (this.lodash.isEmpty(this.daily_recurrence) === false) {
        return this.daily_recurrence.message;
      }
      if (this.lodash.isEmpty(this.monthly_recurrence) === false) {
        return this.monthly_recurrence.message;
      }
      if (this.lodash.isEmpty(this.weekly_recurrence) === false) {
        return this.weekly_recurrence.message;
      }
      return "";
    },
    getInvoiceDuration() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.detail) === false) {
        let index = _this.lodash.findIndex(
          _this.invoiceScheduleList,
          function (row) {
            return row.id == _this.detail.invoice_duration;
          }
        );
        if (_this.lodash.isEmpty(_this.invoiceScheduleList[index]) === false) {
          return _this.invoiceScheduleList[index].value;
        }
      }
      return "";
    },
    getBillingType() {
      if (this.lodash.isEmpty(this.detail) === false) {
        if (this.detail.invoice_type === 1) {
          return "Per Visit";
        }
        if (this.detail.invoice_type === 2) {
          return "Fixed Price";
        }
      }
      return "";
    },
    getScheduleType() {
      if (this.detail.type === 1) {
        return "One-Off Job";
      }
      if (this.detail.type === 2) {
        return "Recurring Job";
      }
      return null;
    },
  },
};
