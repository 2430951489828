var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reminder-detail"},[_c('v-container',{staticClass:"custom-bthrust-style",attrs:{"fluid":""}},[(_vm.billingLoading)?_c('v-skeleton-loader',{staticClass:"custom-skeleton table-rows-text",attrs:{"type":"text@10"}}):_c('v-simple-table',{staticClass:"inner-simple-table",attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[(_vm.lodash.isEmpty(_vm.reminders) === false)?[_vm._l((_vm.reminders),function(row,index){return [(row.header)?_c('tr',{key:index,class:{ 'custom-border-top': index > 0 }},[_c('td',{staticClass:"font-size-16 text-uppercase py-2 font-weight-700 custom-border-bottom",class:[row.header_text],style:({ color: row.header_color }),attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(row.header_text)+" ")])]):[_c('v-hover',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',{key:'reminder-row' + index,class:{
                      'reminder-row-hover': hover,
                      'custom-border-bottom': _vm.reminders[index + 1],
                    },attrs:{"link":""},on:{"click":function($event){return _vm.reminderDetail(row)}}},[_c('td',{staticClass:"font-size-16 font-weight-500 py-3",class:[row.header_text],style:({ color: row.header_color }),attrs:{"width":"400"}},[_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_vm._v(" Invoice Reminder "+_vm._s(_vm.formatDate(row.date))+" ")])]),_c('td',{staticClass:"font-size-16 font-weight-500 py-3",class:[row.header_text],style:({ color: row.header_color })},[_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_vm._v(" "+_vm._s(row.notes)+" ")])])])]}}],null,true)})]]})]:[_c('tr',[_c('td',[_c('p',{staticClass:"m-0 row-not-found text-center font-weight-500 font-size-16"},[_vm._v(" Sorry! No Invoice Reminder(s) Found. ")])])])]],2)]},proxy:true}])})],1),_c('Dialog',{attrs:{"commonDialog":_vm.detailDialog,"dialogWidth":_vm.dialogWidth},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_vm._v(" Reminder ")]),_c('v-col',{class:{ 'text-right': _vm.reminderInfo.status },attrs:{"md":"6"}},[(_vm.reminderInfo.status == 0)?_c('v-layout',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"color":"cyan","to":_vm.getDefaultRoute('invoice.create', {
                  query: {
                    job: _vm.lodash.isEmpty(_vm.detail) == false ? _vm.detail.id : 0,
                    customer:
                      _vm.lodash.isEmpty(_vm.customer) == false
                        ? _vm.customer.customer
                        : 0,
                    contact_person:
                      _vm.lodash.isEmpty(_vm.contactPerson) == false
                        ? _vm.contactPerson.contact_person
                        : 0,
                    property:
                      _vm.lodash.isEmpty(_vm.property) == false
                        ? _vm.property.property
                        : 0,
                  },
                })}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Create Invoice ")],1),_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"color":"red lighten-1"},on:{"click":_vm.deleteReminder}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1)],1):_c('v-chip',{staticClass:"mx-2",attrs:{"color":"light-green darken-1","label":"","outlined":""}},[_vm._v(" Completed ")])],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"custom-border-right",attrs:{"md":"6"}},[_c('p',{staticClass:"m-0 pb-4 font-size-18 font-weight-600 custom-nowrap-ellipsis-two-line color-custom-blue"},[_vm._v(" Invoice reminder for "+_vm._s(_vm.detail.attention)+" for job #"+_vm._s(_vm.detail.barcode)+" ")]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(_vm.lodash.isEmpty(_vm.customer) === false)?_c('v-list-item',_vm._g(_vm._b({staticClass:"py-1 px-0 max-content-width"},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"margin-auto-zero mr-4"},[_c('v-icon',{staticClass:"font-size-28"},[_vm._v("mdi-account")])],1),_c('v-list-item-content',{staticClass:"p-0"},[(_vm.pageLoading)?_c('v-skeleton-loader',{staticClass:"custom-skeleton",attrs:{"type":"text"}}):_c('v-list-item-title',{staticClass:"custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"},[_c('router-link',{attrs:{"to":_vm.getDefaultRoute('customer.detail', {
                            params: {
                              id: _vm.customer.customer,
                            },
                          })}},[_vm._v(_vm._s(_vm.detail.attention))])],1)],1),_c('v-list-item-action',{staticClass:"m-0 ml-4",attrs:{"link":""},on:{"click":function($event){_vm.$router.push(
                        _vm.getDefaultRoute('customer.detail', {
                          params: {
                            id: _vm.customer.customer,
                          },
                        })
                      )}}},[_c('v-icon',{staticClass:"color-custom-blue"},[_vm._v("mdi-link mdi-rotate-135")])],1)],1):_vm._e()]}}])},[_c('span',[_vm._v("Attention")])])],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"py-1 max-content-width"},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"margin-auto-zero mr-4"},[_c('v-icon',{staticClass:"font-size-28"},[_vm._v("mdi-calendar")])],1),_c('v-list-item-content',{staticClass:"p-0"},[_c('v-list-item-title',{staticClass:"font-size-18 font-weight-500"},[_c('a',{staticClass:"custom-nowrap-ellipsis-two-line text-uppercase",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.downloadVisit}},[_vm._v(_vm._s(_vm.formatDate(_vm.reminderInfo.date)))])])],1)],1)]}}])},[_c('span',[_vm._v("Invoice Reminder")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [(_vm.contactPerson)?_c('v-list-item',_vm._g(_vm._b({staticClass:"py-1 max-content-width"},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"margin-auto-zero mr-4"},[_c('v-icon',{staticClass:"font-size-28"},[_vm._v("mdi-phone")])],1),_c('v-list-item-content',{staticClass:"p-0"},[_c('v-list-item-title',{staticClass:"custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"},[_c('a',{attrs:{"href":'tel:' + _vm.contactPerson.primary_phone}},[_vm._v(_vm._s(_vm.contactPerson.primary_phone))])])],1)],1):_vm._e()]}}])},[_c('span',[_vm._v("Contact Phone")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"py-1"},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"margin-auto-zero mr-4"},[_c('v-icon',{staticClass:"font-size-28"},[_vm._v("mdi-home-map-marker")])],1),_c('v-list-item-content',{staticClass:"p-0"},[_c('v-list-item-title',{staticClass:"custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"},[_c('a',{attrs:{"target":"_blank","href":'https://maps.google.com/?q=' + _vm.PropertyDetail}},[_vm._v(_vm._s(_vm.PropertyDetail))])])],1)],1)]}}])},[_c('span',[_vm._v("Property Address")])])],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-textarea',{staticClass:"remove-border-radius custom-grey-border",attrs:{"auto-grow":"","dense":"","filled":"","flat":"","label":"Notes","solo":"","readonly":"","color":"cyan","hide-details":"","row-height":"30"},model:{value:(_vm.reminderInfo.notes),callback:function ($$v) {_vm.$set(_vm.reminderInfo, "notes", $$v)},expression:"reminderInfo.notes"}})],1)],1)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",on:{"click":_vm.closeReminderDetail}},[_vm._v("Close ")])]},proxy:true}])}),_c('ReminderDelete',{attrs:{"deleteDialog":_vm.reminderDeleteDialog,"requestUrl":_vm.reminderDeleteRequestURL},on:{"update:deleteDialog":function($event){_vm.reminderDeleteDialog=$event},"update:delete-dialog":function($event){_vm.reminderDeleteDialog=$event},"update:requestUrl":function($event){_vm.reminderDeleteRequestURL=$event},"update:request-url":function($event){_vm.reminderDeleteRequestURL=$event},"delete:success":_vm.reminderDeleteSuccess,"delete:close":function($event){_vm.reminderDeleteDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }