<template>
  <div :class="{ 'my-1': small }">
    <v-tooltip top content-class="custom-top-tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-bind="attrs"
          v-on="on"
          v-if="text"
          class="mx-2 custom-status font-weight-600 custom-grey-border text-uppercase justify-center"
          label
          :small="small"
          :color.sync="color"
          :text-color.sync="textColor"
          :class="{ 'd-inline': small }"
        >
          <p class="m-0 custom-nowrap-ellipsis">{{ text }}</p>
        </v-chip>
      </template>
      <span>Visit {{ text }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    scheduled: {
      type: Number,
      default: 0,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    text() {
      /*0 => Later, 1 => Onetime, 2 => Again*/
      let result = null;
      switch (this.scheduled) {
        case 0:
          result = "Schedule Later";
          break;
        case 1:
          result = "Scheduled";
          break;
        case 2:
          result = "Re-Scheduled";
          break;
      }
      return result;
    },
    color() {
      /*0 => Later, 1 => Onetime, 2 => Again*/
      let result = null;
      switch (this.scheduled) {
        case 0:
          result = "orange accent-3";
          break;
        case 1:
          result = "light-green darken-2";
          break;
        case 2:
          result = "deep-orange accent-4";
          break;
      }
      return result;
    },
    textColor() {
      /*0 => Later, 1 => Onetime, 2 => Again*/
      let result = null;
      switch (this.scheduled) {
        case 0:
          result = "white";
          break;
        case 1:
          result = "white";
          break;
        case 2:
          result = "white";
          break;
      }
      return result;
    },
  },
};
</script>
