<template>
  <v-container fluid>
    <Dialog :commonDialog="invoiceDialog" :dialogWidth="dialogWidth">
      <template v-slot:title>
        <v-row>
          <v-col md="6"> Create Reminder </v-col>
          <v-col md="6">
            <v-layout class="justify-end">
              <v-btn
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                v-on:click="createInvoiceReminder"
              >
                Save
              </v-btn>
              <v-btn
                v-on:click="$emit('close:invoice-reminder', true)"
                class="mx-2 custom-bold-button"
              >
                Close
              </v-btn>
            </v-layout>
          </v-col>
        </v-row>
      </template>
      <template v-slot:body>
        <v-container>
          <v-row>
            <v-col md="6" class="custom-border-right">
              <p
                class="m-0 pb-4 font-size-18 font-weight-600 custom-nowrap-ellipsis-two-line color-custom-blue"
              >
                Invoice reminder for {{ detail.attention }} for job #{{
                  detail.barcode
                }}
              </p>
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    v-if="lodash.isEmpty(customer) === false"
                    class="py-1 px-0 max-content-width"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-icon class="margin-auto-zero mr-4">
                      <v-icon class="font-size-28">mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="p-0">
                      <v-skeleton-loader
                        v-if="pageLoading"
                        class="custom-skeleton"
                        type="text"
                      ></v-skeleton-loader>
                      <v-list-item-title
                        v-else
                        class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                      >
                        <router-link
                          :to="
                            getDefaultRoute('customer.detail', {
                              params: {
                                id: customer.customer,
                              },
                            })
                          "
                          >{{ detail.attention }}</router-link
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action
                      link
                      class="m-0 ml-4"
                      v-on:click="
                        $router.push(
                          getDefaultRoute('customer.detail', {
                            params: {
                              id: customer.customer,
                            },
                          })
                        )
                      "
                    >
                      <v-icon class="color-custom-blue"
                        >mdi-link mdi-rotate-135</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>
                </template>
                <span>Attention</span>
              </v-tooltip>
            </v-col>
            <v-col md="6">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    v-if="contactPerson"
                    class="py-1 max-content-width"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-icon class="margin-auto-zero mr-4">
                      <v-icon class="font-size-28">mdi-phone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="p-0">
                      <v-list-item-title
                        class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                      >
                        <a :href="'tel:' + contactPerson.primary_phone">{{
                          contactPerson.primary_phone
                        }}</a>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <span>Contact Phone</span>
              </v-tooltip>
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item class="py-1" v-bind="attrs" v-on="on">
                    <v-list-item-icon class="margin-auto-zero mr-4">
                      <v-icon class="font-size-28">mdi-home-map-marker</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="p-0">
                      <v-list-item-title
                        class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                      >
                        <a
                          target="_blank"
                          :href="'https://maps.google.com/?q=' + PropertyDetail"
                          >{{ PropertyDetail }}</a
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <span>Property Address</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col md="6">
              <Datepicker
                solo
                v-on:update:date-picker="setReminderDate"
              ></Datepicker>
            </v-col>
            <v-col md="6">
              <v-textarea
                v-model="reminder.notes"
                auto-grow
                dense
                filled
                flat
                :disabled="formLoading"
                label="Notes"
                solo
                color="cyan"
                class="remove-border-radius custom-grey-border"
                hide-details
                row-height="30"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import { POST } from "@/core/services/store/request.module";
import { DialogJobEventBus } from "@/core/lib/job/dialog.job.lib";

export default {
  mixins: [CommonMixin],
  props: {
    pageLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    invoiceDialog: {
      type: Boolean,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    teams: {
      required: true,
      type: Array,
      default() {
        return new Array();
      },
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    billing: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data() {
    return {
      reminder: {
        date: null,
        notes: null,
      },
    };
  },
  components: {
    Dialog,
    Datepicker,
  },
  methods: {
    setReminderDate(param) {
      this.reminder.date = param;
    },
    createInvoiceReminder() {
      const _this = this;

      const formData = {
        date: _this.reminder.date,
        notes: _this.reminder.notes,
      };

      _this.formLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "job/" + _this.detail.id + "/invoice-reminder",
          data: formData,
        })
        .then(() => {
          _this.$emit("create:invoice-reminder", true);
          DialogJobEventBus.$emit("load:invoice-reminder", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  mounted() {},
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
    attendees() {
      return this.lodash.isEmpty(this.teams) === false
        ? this.teams.map((row) => {
            if (row.engineer) {
              return {
                name: row.engineer.full_name,
                email: row.engineer.primary_email,
              };
            }
          })
        : [];
    },
    PropertyDetail() {
      let propertyArr = new Array();
      if (this.lodash.isEmpty(this.property) === false) {
        if (this.property.unit_no) {
          propertyArr.push(this.property.unit_no);
        }
        if (this.property.street_1) {
          propertyArr.push(this.property.street_1);
        }
        if (this.property.street_2) {
          propertyArr.push(this.property.street_2);
        }
        if (this.property.zip_code) {
          propertyArr.push(this.property.zip_code);
        }
        if (this.CityName) {
          propertyArr.push(this.CityName);
        }
        if (this.StateName) {
          propertyArr.push(this.StateName);
        }
        if (this.CountryName) {
          propertyArr.push(this.CountryName);
        }
      }
      return propertyArr.join(", ");
    },
    CityName() {
      return this.lodash.isEmpty(this.property.city) === false
        ? this.property.city.name
        : null;
    },
    StateName() {
      return this.lodash.isEmpty(this.property.state) === false
        ? this.property.state.name
        : null;
    },
    CountryName() {
      return this.lodash.isEmpty(this.property.country) === false
        ? this.property.country.name
        : null;
    },
  },
};
</script>
