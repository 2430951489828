<template>
  <div class="reminder-detail">
    <v-container fluid class="custom-bthrust-style">
      <v-skeleton-loader
        type="text@10"
        class="custom-skeleton table-rows-text"
        v-if="billingLoading"
      ></v-skeleton-loader>
      <v-simple-table v-else class="inner-simple-table" fixed-header>
        <template v-slot:default>
          <tbody>
            <template v-if="lodash.isEmpty(reminders) === false">
              <template v-for="(row, index) in reminders">
                <tr
                  v-if="row.header"
                  :key="index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="font-size-16 text-uppercase py-2 font-weight-700 custom-border-bottom"
                    :class="[row.header_text]"
                    :style="{ color: row.header_color }"
                  >
                    {{ row.header_text }}
                  </td>
                </tr>
                <template v-else>
                  <v-hover v-slot="{ hover }" :key="index">
                    <tr
                      link
                      :key="'reminder-row' + index"
                      :class="{
                        'reminder-row-hover': hover,
                        'custom-border-bottom': reminders[index + 1],
                      }"
                      v-on:click="reminderDetail(row)"
                    >
                      <td
                        width="400"
                        :class="[row.header_text]"
                        class="font-size-16 font-weight-500 py-3"
                        :style="{ color: row.header_color }"
                      >
                        <p class="m-0 custom-nowrap-ellipsis">
                          Invoice Reminder {{ formatDate(row.date) }}
                        </p>
                      </td>
                      <td
                        :class="[row.header_text]"
                        class="font-size-16 font-weight-500 py-3"
                        :style="{ color: row.header_color }"
                      >
                        <p class="m-0 custom-nowrap-ellipsis">
                          {{ row.notes }}
                        </p>
                      </td>
                    </tr>
                  </v-hover>
                </template>
              </template>
            </template>
            <template v-else>
              <tr>
                <td>
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    Sorry! No Invoice Reminder(s) Found.
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
    <Dialog :commonDialog="detailDialog" :dialogWidth="dialogWidth">
      <template v-slot:title>
        <v-row>
          <v-col md="6"> Reminder </v-col>
          <v-col md="6" :class="{ 'text-right': reminderInfo.status }">
            <v-layout v-if="reminderInfo.status == 0" class="justify-end">
              <v-btn
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                :to="
                  getDefaultRoute('invoice.create', {
                    query: {
                      job: lodash.isEmpty(detail) == false ? detail.id : 0,
                      customer:
                        lodash.isEmpty(customer) == false
                          ? customer.customer
                          : 0,
                      contact_person:
                        lodash.isEmpty(contactPerson) == false
                          ? contactPerson.contact_person
                          : 0,
                      property:
                        lodash.isEmpty(property) == false
                          ? property.property
                          : 0,
                    },
                  })
                "
              >
                <v-icon left>mdi-plus</v-icon>
                Create Invoice
              </v-btn>
              <v-btn
                v-on:click="deleteReminder"
                class="mx-2 custom-bold-button white--text"
                color="red lighten-1"
              >
                <v-icon left>mdi-delete</v-icon>
                Delete
              </v-btn>
            </v-layout>
            <v-chip
              v-else
              class="mx-2"
              color="light-green darken-1"
              label
              outlined
            >
              Completed
            </v-chip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:body>
        <v-container>
          <v-row>
            <v-col md="6" class="custom-border-right">
              <p
                class="m-0 pb-4 font-size-18 font-weight-600 custom-nowrap-ellipsis-two-line color-custom-blue"
              >
                Invoice reminder for {{ detail.attention }} for job #{{
                  detail.barcode
                }}
              </p>
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    v-if="lodash.isEmpty(customer) === false"
                    class="py-1 px-0 max-content-width"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-icon class="margin-auto-zero mr-4">
                      <v-icon class="font-size-28">mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="p-0">
                      <v-skeleton-loader
                        v-if="pageLoading"
                        class="custom-skeleton"
                        type="text"
                      ></v-skeleton-loader>
                      <v-list-item-title
                        v-else
                        class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                      >
                        <router-link
                          :to="
                            getDefaultRoute('customer.detail', {
                              params: {
                                id: customer.customer,
                              },
                            })
                          "
                          >{{ detail.attention }}</router-link
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action
                      link
                      class="m-0 ml-4"
                      v-on:click="
                        $router.push(
                          getDefaultRoute('customer.detail', {
                            params: {
                              id: customer.customer,
                            },
                          })
                        )
                      "
                    >
                      <v-icon class="color-custom-blue"
                        >mdi-link mdi-rotate-135</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>
                </template>
                <span>Attention</span>
              </v-tooltip>
            </v-col>
            <v-col md="6">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    class="py-1 max-content-width"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-icon class="margin-auto-zero mr-4">
                      <v-icon class="font-size-28">mdi-calendar</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="p-0">
                      <v-list-item-title class="font-size-18 font-weight-500">
                        <a
                          href="javascript:void(0)"
                          v-on:click="downloadVisit"
                          class="custom-nowrap-ellipsis-two-line text-uppercase"
                          >{{ formatDate(reminderInfo.date) }}</a
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <span>Invoice Reminder</span>
              </v-tooltip>
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    v-if="contactPerson"
                    class="py-1 max-content-width"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-icon class="margin-auto-zero mr-4">
                      <v-icon class="font-size-28">mdi-phone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="p-0">
                      <v-list-item-title
                        class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                      >
                        <a :href="'tel:' + contactPerson.primary_phone">{{
                          contactPerson.primary_phone
                        }}</a>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <span>Contact Phone</span>
              </v-tooltip>
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item class="py-1" v-bind="attrs" v-on="on">
                    <v-list-item-icon class="margin-auto-zero mr-4">
                      <v-icon class="font-size-28">mdi-home-map-marker</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="p-0">
                      <v-list-item-title
                        class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                      >
                        <a
                          target="_blank"
                          :href="'https://maps.google.com/?q=' + PropertyDetail"
                          >{{ PropertyDetail }}</a
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <span>Property Address</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col md="12">
              <v-textarea
                v-model="reminderInfo.notes"
                auto-grow
                dense
                filled
                flat
                label="Notes"
                solo
                readonly
                color="cyan"
                class="remove-border-radius custom-grey-border"
                hide-details
                row-height="30"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          v-on:click="closeReminderDetail"
          class="mx-2 custom-grey-border custom-bold-button"
          >Close
        </v-btn>
      </template>
    </Dialog>
    <ReminderDelete
      :deleteDialog.sync="reminderDeleteDialog"
      :requestUrl.sync="reminderDeleteRequestURL"
      v-on:delete:success="reminderDeleteSuccess"
      v-on:delete:close="reminderDeleteDialog = false"
    ></ReminderDelete>
  </div>
</template>

<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ReminderDelete from "@/view/pages/partials/Delete-Request-Template.vue";
import { downloadEvent } from "@/core/lib/ics.lib";
import { DialogJobEventBus } from "@/core/lib/job/dialog.job.lib";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, JobRequestMixin],
  props: {
    pageLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    billing: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.job = this.lodash.toSafeInteger(param.id);
        this.getInvoiceReminderList();
      },
    },
  },
  components: {
    Dialog,
    ReminderDelete,
  },
  data() {
    return {
      reminders: [],
      detailDialog: false,
      billingLoading: false,
      job: 0,
      reminderInfo: {},
      reminderDeleteDialog: false,
      reminderDeleteRequestURL: "",
      timeout: null,
      timeoutLimit: 500,
    };
  },
  methods: {
    deleteReminder() {
      this.reminderDeleteRequestURL =
        "job/" + this.detail.id + "/reminder/" + this.reminderInfo.id;
      this.reminderDeleteDialog = true;
    },
    reminderDeleteSuccess() {
      this.reminderDeleteDialog = false;
      this.reminderDeleteRequestURL = "";
      this.closeReminderDetail();
      this.getInvoiceReminderList();
      DialogJobEventBus.$emit("load:job", true);
    },
    reminderDetail(row) {
      this.reminderInfo = row;
      this.detailDialog = true;
    },
    closeReminderDetail() {
      this.reminderInfo = {};
      this.detailDialog = false;
    },
    downloadVisit() {
      try {
        let startDateArray = moment(this.reminderInfo.date)
          .format("YYYY-MM-DD")
          .split("-");
        let endDateArray = moment(this.reminderInfo.date)
          .format("YYYY-MM-DD")
          .split("-");
        const reminder = {
          start: startDateArray,
          end: endDateArray,
          title:
            "Invoice reminder for " +
            this.detail.attention +
            " for job # " +
            this.detail.barcode,
          description: this.reminderInfo.notes,
          location: this.PropertyDetail,
          url: process.env.VUE_APP_BASE_URL,
          geo: { lat: this.property.latitude, lon: this.property.longitude },
          categories: this.detail.ticket_type,
          status: "CONFIRMED",
          organizer: {
            name: this.detail.added_by.display_name,
            email: this.detail.added_by.user_email,
          },
          attendees: this.attendees,
        };
        downloadEvent(reminder);
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
    getInvoiceReminderList() {
      const _this = this;
      clearTimeout(_this.timeout);
      _this.billingLoading = true;
      _this.timeout = setTimeout(function () {
        _this
          .getInvoiceReminders(_this.job)
          .then((response) => {
            _this.reminders = response;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.billingLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  mounted() {
    this.getInvoiceReminderList();
  },
  created() {
    const _this = this;
    _this.job = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.job || _this.job <= 0) {
      _this.$router.go(-1);
    }

    DialogJobEventBus.$on("load:invoice-reminder", function () {
      _this.getInvoiceReminderList();
    });
  },
  beforeDestroy() {
    /*DialogJobEventBus.$off("load:invoice-reminder");*/
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
    attendees() {
      return this.lodash.isEmpty(this.teams) === false
        ? this.teams.map((row) => {
            if (row.engineer) {
              return {
                name: row.engineer.full_name,
                email: row.engineer.primary_email,
              };
            }
          })
        : [];
    },
    PropertyDetail() {
      let propertyArr = new Array();
      if (this.lodash.isEmpty(this.property) === false) {
        if (this.property.unit_no) {
          propertyArr.push(this.property.unit_no);
        }
        if (this.property.street_1) {
          propertyArr.push(this.property.street_1);
        }
        if (this.property.street_2) {
          propertyArr.push(this.property.street_2);
        }
        if (this.property.zip_code) {
          propertyArr.push(this.property.zip_code);
        }
        if (this.CityName) {
          propertyArr.push(this.CityName);
        }
        if (this.StateName) {
          propertyArr.push(this.StateName);
        }
        if (this.CountryName) {
          propertyArr.push(this.CountryName);
        }
      }
      return propertyArr.join(", ");
    },
    CityName() {
      return this.lodash.isEmpty(this.property.city) === false
        ? this.property.city.name
        : null;
    },
    StateName() {
      return this.lodash.isEmpty(this.property.state) === false
        ? this.property.state.name
        : null;
    },
    CountryName() {
      return this.lodash.isEmpty(this.property.country) === false
        ? this.property.country.name
        : null;
    },
  },
};
</script>
