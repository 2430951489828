<template>
  <v-container
    fluid
    class="custom-bthrust-style"
    v-if="getPermission('attachment:view')"
  >
    <v-row>
      <v-col md="4" class="custom-border-right">
        <v-row>
          <v-col md="6">
            <p
              class="m-0 font-size-16 font-weight-600 float-left color-custom-blue text-uppercase"
            >
              Job
            </p>
          </v-col>
          <template v-if="getPermission('attachment:create')">
            <v-col md="6" class="text-right">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2 custom-bold-button white--text"
                    color="cyan"
                    tile
                    v-on:click="addTicketAttachmentDialog"
                  >
                    <v-icon>mdi-attachment mdi-rotate-135</v-icon>
                  </v-btn>
                </template>
                <span>Add Job Attachment</span>
              </v-tooltip>
            </v-col>
          </template>
        </v-row>
        <template v-if="ticketAttachment.length > 0">
          <template v-for="(ticket, index) in ticketAttachment">
            <v-list-item
              v-if="ticket.file"
              class="custom-border-bottom"
              :class="{ 'custom-border-top': index <= 0 }"
              :key="'ticket-' + index"
            >
              <v-tooltip right content-class="custom-right-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-avatar size="100" v-bind="attrs" v-on="on">
                    <span class="svg-icon svg-icon-lg custom-mime-icon mr-4">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="
                          $assetURL(
                            'media/mime/' +
                              getFileExtension(ticket.file.path) +
                              '.svg'
                          )
                        "
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </v-list-item-avatar>
                </template>
                <span>{{ ticket.file.name }}</span>
              </v-tooltip>
              <v-list-item-content>
                <v-list-item-title class="font-size-16 font-weight-500"
                  >Job #{{ detail.barcode }}</v-list-item-title
                >

                <v-list-item-subtitle>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        class="m-0 font-size-16 font-weight-500 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ ticket.added_by.display_name }}
                      </p>
                    </template>
                    <span>{{ ticket.added_by.user_email }}</span>
                  </v-tooltip>
                </v-list-item-subtitle>

                <v-list-item-subtitle>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        class="m-0 font-size-16 font-weight-500 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ ticket.created_at }}
                      </p>
                    </template>
                    <span>{{ formatDateTime(ticket.added_at) }}</span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <template v-if="getPermission('attachment:delete')">
                  <v-btn
                    icon
                    :loading="isDeleting(index)"
                    :disabled="primaryLoader || isDeleting(index)"
                    v-on:click="
                      deleteAPIFile(ticket.id, index, ticketAttachment)
                    "
                  >
                    <v-icon color="red lighten-1">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <template v-if="getPermission('attachment:view')">
                  <v-btn
                    icon
                    :loading="isDownloading(index)"
                    :disabled="primaryLoader || isDownloading(index)"
                    v-on:click="
                      downloadAPIFile(ticket.id, ticket.file.name, index)
                    "
                  >
                    <v-icon color="cyan">mdi-download</v-icon>
                  </v-btn>
                </template>
              </v-list-item-action>
            </v-list-item>
          </template>
        </template>
        <template v-else>
          <p
            class="m-0 custom-border-top row-not-found text-center font-weight-500 font-size-16"
          >
            <template v-if="documentLoading">
              <span class="py-8 d-block">Getting your attachments</span>
              <v-progress-linear
                color="cyan"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </template>
            <template v-else>
              <img
                :src="$assetURL('media/error/empty.png')"
                class="row-not-found-image mt-4"
              />
              <span class="d-block mt-4"
                >Uhh... There are no job attachment at the moment.</span
              >
            </template>
          </p>
        </template>
      </v-col>
      <v-col md="4" class="custom-border-right">
        <v-row>
          <v-col md="6">
            <p
              class="m-0 font-size-16 font-weight-600 float-left color-custom-blue text-uppercase"
            >
              Visit
            </p>
          </v-col>
          <template v-if="getPermission('attachment:create')">
            <v-col md="6" class="text-right">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2 custom-bold-button white--text"
                    color="cyan"
                    tile
                    v-on:click="addVisitAttachmentDialog"
                  >
                    <v-icon>mdi-attachment mdi-rotate-135</v-icon>
                  </v-btn>
                </template>
                <span>Add Visit Attachment</span>
              </v-tooltip>
            </v-col>
          </template>
        </v-row>
        <template v-if="visitAttachment.length > 0">
          <template v-for="(document, rindex) in visitAttachment">
            <div
              :key="'visit-' + rindex"
              :class="{ 'custom-border-top': rindex <= 0 }"
            >
              <v-list-item class="custom-border-bottom">
                <v-tooltip right content-class="custom-right-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-avatar size="100" v-bind="attrs" v-on="on">
                      <span class="svg-icon svg-icon-lg custom-mime-icon mr-4">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          :src="
                            $assetURL(
                              'media/mime/' +
                                getFileExtension(document.file.path) +
                                '.svg'
                            )
                          "
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </v-list-item-avatar>
                  </template>
                  <span>{{ document.file.name }}</span>
                </v-tooltip>
                <v-list-item-content v-if="document.visit_note">
                  <v-list-item-title
                    v-if="document.visit_note.visit"
                    class="font-size-16 font-weight-500"
                    >Visit #{{
                      document.visit_note.visit.barcode
                    }}</v-list-item-title
                  >

                  <v-list-item-subtitle>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <p
                          class="m-0 font-size-16 font-weight-500 max-content-width"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ document.added_by.display_name }}
                        </p>
                      </template>
                      <span>{{ document.added_by.user_email }}</span>
                    </v-tooltip>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <p
                          class="m-0 font-size-16 font-weight-500 max-content-width"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ document.created_at }}
                        </p>
                      </template>
                      <span>{{ formatDateTime(document.added_at) }}</span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <template v-if="getPermission('attachment:delete')">
                    <v-btn
                      icon
                      :loading="isDeleting(rindex)"
                      :disabled="primaryLoader || isDeleting(rindex)"
                      v-on:click="
                        deleteAPIFile(document.id, rindex, visitAttachment)
                      "
                    >
                      <v-icon color="red lighten-1">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <template v-if="getPermission('attachment:view')">
                    <v-btn
                      icon
                      :loading="isDownloading(rindex)"
                      :disabled="primaryLoader || isDownloading(rindex)"
                      v-on:click="
                        downloadAPIFile(document.id, document.file.name, rindex)
                      "
                    >
                      <v-icon color="cyan">mdi-download</v-icon>
                    </v-btn>
                  </template>
                </v-list-item-action>
              </v-list-item>
            </div>
          </template>
        </template>
        <template v-else>
          <p
            class="m-0 custom-border-top row-not-found text-center font-weight-500 font-size-16"
          >
            <template v-if="documentLoading">
              <span class="py-8 d-block">Getting your attachments</span>
              <v-progress-linear
                color="cyan"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </template>
            <template v-else>
              <img
                :src="$assetURL('media/error/empty.png')"
                class="row-not-found-image mt-4"
              />
              <span class="d-block mt-4"
                >Uhh... There are no visit attachment at the moment.</span
              >
            </template>
          </p>
        </template>
      </v-col>
      <v-col md="4">
        <v-row>
          <v-col md="6">
            <p
              class="m-0 font-size-16 font-weight-600 float-left color-custom-blue text-uppercase"
            >
              Closer
            </p>
          </v-col>
          <template v-if="getPermission('attachment:create')">
            <v-col md="6" class="text-right">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2 custom-bold-button white--text"
                    color="cyan"
                    tile
                    v-on:click="addCloserAttachmentDialog"
                  >
                    <v-icon>mdi-attachment mdi-rotate-135</v-icon>
                  </v-btn>
                </template>
                <span>Add Closer Attachment</span>
              </v-tooltip>
            </v-col>
          </template>
        </v-row>
        <template v-if="closerAttachment.length > 0">
          <template v-for="(document, rindex) in closerAttachment">
            <div
              :key="'closer-' + rindex"
              :class="{ 'custom-border-top': rindex <= 0 }"
            >
              <v-list-item class="custom-border-bottom">
                <v-tooltip right content-class="custom-right-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-avatar size="100" v-bind="attrs" v-on="on">
                      <span class="svg-icon svg-icon-lg custom-mime-icon mr-4">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          :src="
                            $assetURL(
                              'media/mime/' +
                                getFileExtension(document.file.path) +
                                '.svg'
                            )
                          "
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </v-list-item-avatar>
                  </template>
                  <span>{{ document.file.name }}</span>
                </v-tooltip>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="document.closer"
                    class="font-size-16 font-weight-500"
                    >Closer #{{ document.closer.barcode }}</v-list-item-title
                  >

                  <v-list-item-subtitle>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <p
                          class="m-0 font-size-16 font-weight-500 max-content-width"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ document.added_by.display_name }}
                        </p>
                      </template>
                      <span>{{ document.added_by.user_email }}</span>
                    </v-tooltip>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <p
                          class="m-0 font-size-16 font-weight-500 max-content-width"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ document.created_at }}
                        </p>
                      </template>
                      <span>{{ formatDateTime(document.added_at) }}</span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <template v-if="getPermission('attachment:delete')">
                    <v-btn
                      icon
                      :loading="isDeleting(rindex)"
                      :disabled="primaryLoader || isDeleting(rindex)"
                      v-on:click="
                        deleteAPIFile(document.id, rindex, closerAttachment)
                      "
                    >
                      <v-icon color="red lighten-1">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <template v-if="getPermission('attachment:view')">
                    <v-btn
                      icon
                      :loading="isDownloading(rindex)"
                      :disabled="primaryLoader || isDownloading(rindex)"
                      v-on:click="
                        downloadAPIFile(document.id, document.file.name, rindex)
                      "
                    >
                      <v-icon color="cyan">mdi-download</v-icon>
                    </v-btn>
                  </template>
                </v-list-item-action>
              </v-list-item>
            </div>
          </template>
        </template>
        <template v-else>
          <p
            class="m-0 custom-border-top row-not-found text-center font-weight-500 font-size-16"
          >
            <template v-if="documentLoading">
              <span class="py-8 d-block">Getting your attachments</span>
              <v-progress-linear
                color="cyan"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </template>
            <template v-else>
              <img
                :src="$assetURL('media/error/empty.png')"
                class="row-not-found-image mt-4"
              />
              <span class="d-block mt-4"
                >Uhh... There are no closer attachment at the moment.</span
              >
            </template>
          </p>
        </template>
      </v-col>
    </v-row>
    <template v-if="getPermission('attachment:create')">
      <Dialog
        :commonDialog.sync="attachmentDialog"
        :dialogWidth.sync="dialogWidth"
      >
        <template v-slot:title>
          Add Attachment | Job #{{ detail.barcode }}
        </template>
        <template v-slot:body>
          <v-container fluid>
            <v-form
              ref="attachmentForm"
              v-model="formValid"
              lazy-validation
              v-on:submit.stop.prevent="updateAttachment"
            >
              <v-row>
                <v-col md="12" v-if="addVisitAttachment">
                  <v-autocomplete
                    v-model="attachment_data.visit"
                    hide-details
                    :items="visitList"
                    dense
                    flat
                    filled
                    label="Visit"
                    item-color="cyan"
                    color="cyan"
                    solo
                    :disabled="formLoading"
                    class="remove-border-radius custom-grey-border"
                    item-text="barcode"
                    item-value="id"
                    :rules="[
                      validateRules.required(
                        attachment_data.visit,
                        'Visit',
                        true
                      ),
                    ]"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'No Visit(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ item }">
                      <p class="m-0 font-size-16 text-ellipsis text-capitalize">
                        {{ item.barcode }} ({{
                          formatDateTime(item.started_at)
                        }}
                        - {{ formatTime(item.finished_at) }})
                      </p>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          class="font-size-16 pl-2 text-ellipsis pb-1 text-capitalize"
                          >{{ item.barcode }} ({{
                            formatDateTime(item.started_at)
                          }}
                          -
                          {{ formatTime(item.finished_at) }})</v-list-item-title
                        >
                        <v-list-item-subtitle
                          class="font-size-14 pl-2 text-ellipsis pb-1"
                          >{{ item.title }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col md="12" v-if="addCloserAttachment">
                  <v-autocomplete
                    v-model="attachment_data.closer"
                    hide-details
                    :items="closerList"
                    dense
                    flat
                    filled
                    label="Closer"
                    item-color="cyan"
                    color="cyan"
                    solo
                    :disabled="formLoading"
                    class="remove-border-radius custom-grey-border"
                    item-text="barcode"
                    item-value="id"
                    :rules="[
                      validateRules.required(
                        attachment_data.closer,
                        'Closer',
                        true
                      ),
                    ]"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'No Closer(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >#{{ item.barcode }}</v-list-item-title
                      >
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16 text--secondary"
                        >#{{ item.visit.barcode }} ({{
                          formatDateTime(item.visit.started_at)
                        }}
                        -
                        {{
                          formatTime(item.visit.finished_at)
                        }})</v-list-item-title
                      >
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-capitalize font-weight-500 font-size-16"
                          >#{{ item.barcode }}</v-list-item-title
                        >
                        <v-list-item-title
                          class="text-capitalize font-weight-500 font-size-16 text--secondary"
                          >#{{ item.visit.barcode }} ({{
                            formatDateTime(item.visit.started_at)
                          }}
                          -
                          {{
                            formatTime(item.visit.finished_at)
                          }})</v-list-item-title
                        >
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col md="12" v-if="attachmentDialog">
                  <FileTemplate
                    allowUpload
                    isMinDisplay
                    v-on:file:updated="updateFiles"
                  ></FileTemplate>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            :loading="formLoading"
            :disabled="!formValid || formLoading"
            v-on:click="updateAttachment"
          >
            Save
          </v-btn>
          <v-btn
            :disabled="formLoading"
            class="custom-grey-border custom-bold-button"
            v-on:click="closeAttachmentDialog"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
    </template>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";
import { AttachmentEventBus } from "@/core/lib/attachment.lib";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import { POST } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  mixins: [FileManagerMixin, JobRequestMixin, CommonMixin, ValidationMixin],
  props: {
    pageLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data() {
    return {
      job: 0,
      attachmentDialog: false,
      addTicketAttachment: false,
      addVisitAttachment: false,
      documentLoading: true,
      addCloserAttachment: false,
      ticketAttachment: [],
      visitAttachment: [],
      closerAttachment: [],
      newAttachments: [],
      visitList: [],
      closerList: [],
      attachment_data: {
        visit: 0,
        closer: 0,
      },
    };
  },
  components: {
    Dialog,
    FileTemplate,
  },
  methods: {
    getTicketDocuments() {
      const _this = this;
      _this.documentLoading = true;
      _this
        .getDocuments(_this.job)
        .then(({ ticket, visit_note, closer, document_count }) => {
          _this.ticketAttachment = ticket || [];
          _this.visitAttachment = visit_note || [];
          _this.closerAttachment = closer || [];
          _this.$emit("total:attachment", document_count);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.documentLoading = false;
        });
    },
    addTicketAttachmentDialog() {
      this.addTicketAttachment = true;
      this.attachmentDialog = true;
      this.newAttachments = [];
      this.attachment_data.visit = 0;
      this.attachment_data.closer = 0;
    },
    addVisitAttachmentDialog() {
      this.addVisitAttachment = true;
      this.attachmentDialog = true;
      this.newAttachments = [];
      this.attachment_data.visit = 0;
      this.attachment_data.closer = 0;
    },
    addCloserAttachmentDialog() {
      this.addCloserAttachment = true;
      this.attachmentDialog = true;
      this.newAttachments = [];
      this.attachment_data.visit = 0;
      this.attachment_data.closer = 0;
    },
    closeAttachmentDialog() {
      this.addTicketAttachment = false;
      this.addVisitAttachment = false;
      this.addCloserAttachment = false;
      this.attachmentDialog = false;
      this.newAttachments = [];
      this.attachment_data.visit = 0;
      this.attachment_data.closer = 0;
    },
    updateFiles(param) {
      this.newAttachments = param;
    },
    updateAttachment() {
      const _this = this;
      if (!_this.$refs.attachmentForm.validate()) {
        return false;
      }

      const formData = {
        visit:
          _this.lodash.toSafeInteger(_this.attachment_data.visit) > 0
            ? _this.lodash.toSafeInteger(_this.attachment_data.visit)
            : null,
        closer:
          _this.lodash.toSafeInteger(_this.attachment_data.closer) > 0
            ? _this.lodash.toSafeInteger(_this.attachment_data.closer)
            : null,
        attachments: _this.newAttachments,
      };

      if (_this.addVisitAttachment && !formData.visit) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select a Visit.")
        );
        return false;
      }

      if (_this.addCloserAttachment && !formData.closer) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select a Closer.")
        );
        return false;
      }

      _this.formLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "job/" + _this.detail.id + "/attachments",
          data: formData,
        })
        .then(() => {
          _this.closeAttachmentDialog();
          _this.getTicketDocuments();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getVisitList() {
      const _this = this;
      _this
        .getVisitsForFilter(_this.job)
        .then((response) => {
          _this.visitList = response;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getCloserList() {
      const _this = this;
      _this
        .getClosers(_this.job)
        .then((response) => {
          _this.closerList = response;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        for (let i = 0; i < param.length; i++) {
          if (_this.lodash.isEmpty(param[i].engineer) === false) {
            display_name.push(param[i].engineer.display_name);
          }
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    },
  },
  mounted() {
    this.getTicketDocuments();
    this.getVisitList();
    this.getCloserList();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
  beforeDestroy() {
    /*AttachmentEventBus.$off("load:attachment");*/
  },
  created() {
    const _this = this;

    _this.job = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.job || _this.job <= 0) {
      _this.$router.go(-1);
    }

    AttachmentEventBus.$on("load:attachment", function (job) {
      _this.getTicketDocuments(job);
    });
  },
};
</script>
