var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('Dialog',{attrs:{"commonDialog":_vm.invoiceDialog,"dialogWidth":_vm.dialogWidth},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_vm._v(" Create Reminder ")]),_c('v-col',{attrs:{"md":"6"}},[_c('v-layout',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"color":"cyan"},on:{"click":_vm.createInvoiceReminder}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"mx-2 custom-bold-button",on:{"click":function($event){return _vm.$emit('close:invoice-reminder', true)}}},[_vm._v(" Close ")])],1)],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"custom-border-right",attrs:{"md":"6"}},[_c('p',{staticClass:"m-0 pb-4 font-size-18 font-weight-600 custom-nowrap-ellipsis-two-line color-custom-blue"},[_vm._v(" Invoice reminder for "+_vm._s(_vm.detail.attention)+" for job #"+_vm._s(_vm.detail.barcode)+" ")]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.lodash.isEmpty(_vm.customer) === false)?_c('v-list-item',_vm._g(_vm._b({staticClass:"py-1 px-0 max-content-width"},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"margin-auto-zero mr-4"},[_c('v-icon',{staticClass:"font-size-28"},[_vm._v("mdi-account")])],1),_c('v-list-item-content',{staticClass:"p-0"},[(_vm.pageLoading)?_c('v-skeleton-loader',{staticClass:"custom-skeleton",attrs:{"type":"text"}}):_c('v-list-item-title',{staticClass:"custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"},[_c('router-link',{attrs:{"to":_vm.getDefaultRoute('customer.detail', {
                            params: {
                              id: _vm.customer.customer,
                            },
                          })}},[_vm._v(_vm._s(_vm.detail.attention))])],1)],1),_c('v-list-item-action',{staticClass:"m-0 ml-4",attrs:{"link":""},on:{"click":function($event){_vm.$router.push(
                        _vm.getDefaultRoute('customer.detail', {
                          params: {
                            id: _vm.customer.customer,
                          },
                        })
                      )}}},[_c('v-icon',{staticClass:"color-custom-blue"},[_vm._v("mdi-link mdi-rotate-135")])],1)],1):_vm._e()]}}])},[_c('span',[_vm._v("Attention")])])],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [(_vm.contactPerson)?_c('v-list-item',_vm._g(_vm._b({staticClass:"py-1 max-content-width"},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"margin-auto-zero mr-4"},[_c('v-icon',{staticClass:"font-size-28"},[_vm._v("mdi-phone")])],1),_c('v-list-item-content',{staticClass:"p-0"},[_c('v-list-item-title',{staticClass:"custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"},[_c('a',{attrs:{"href":'tel:' + _vm.contactPerson.primary_phone}},[_vm._v(_vm._s(_vm.contactPerson.primary_phone))])])],1)],1):_vm._e()]}}])},[_c('span',[_vm._v("Contact Phone")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"py-1"},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"margin-auto-zero mr-4"},[_c('v-icon',{staticClass:"font-size-28"},[_vm._v("mdi-home-map-marker")])],1),_c('v-list-item-content',{staticClass:"p-0"},[_c('v-list-item-title',{staticClass:"custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"},[_c('a',{attrs:{"target":"_blank","href":'https://maps.google.com/?q=' + _vm.PropertyDetail}},[_vm._v(_vm._s(_vm.PropertyDetail))])])],1)],1)]}}])},[_c('span',[_vm._v("Property Address")])])],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('Datepicker',{attrs:{"solo":""},on:{"update:date-picker":_vm.setReminderDate}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-textarea',{staticClass:"remove-border-radius custom-grey-border",attrs:{"auto-grow":"","dense":"","filled":"","flat":"","disabled":_vm.formLoading,"label":"Notes","solo":"","color":"cyan","hide-details":"","row-height":"30"},model:{value:(_vm.reminder.notes),callback:function ($$v) {_vm.$set(_vm.reminder, "notes", $$v)},expression:"reminder.notes"}})],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }